<template>
<div style="width:100%">
  <v-list-item class="pa-0">
      <v-row no-gutters>
        <v-col cols="12" md="1" align-self="center">
            <v-row no-gutters align="center">
              <v-col cols="12" class="text-center">
                <v-row no-gutters>
                  <v-col cols="12" md="10" class="text-center">
                    <span style="font-size: 11px;">{{ item.codigo }}</span>
                  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="10" class="text-center">
                  <span style="font-size: 9px;">{{ item.codigo_sfc }}</span>
                </v-col>
              </v-row>
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="2">
            <v-list-item-content class="pa-0">
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.identificacion }}</span></v-list-item-title>
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.nombres }}</span></v-list-item-title>
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.apellido1 + ' ' + item.gescontacto.apellido2 }}</span></v-list-item-title>
            </v-list-item-content>
        </v-col>
        <v-col cols="12" md="3">
            <v-list-item-content class="pa-0">
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ (item.canal===null)? item.ptorecepcion.descripcion : item.canal.descripcion }}</span></v-list-item-title>
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.retroalimentacion.descripcion }}</span></v-list-item-title>
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.proceso.descripcion }}</span></v-list-item-title>
                <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.ramo.descripcion }}</span></v-list-item-title>
            </v-list-item-content>
        </v-col>
        <v-col cols="12" md="3">
              <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CREADO: {{ rec_function_fecha_colombia(item.created_at) }}</span></v-col></v-row>
              <v-row no-gutters><v-col class="pl-2"><v-icon v-if="item.usuario" size="15">mdi-account-circle</v-icon><span class="pl-1" style="font-size:10px">{{ (item.usuario === null)? '':item.usuario.nombre_usuario + ' ' + item.usuario.apellido_usuario }}</span></v-col></v-row>
              <v-row no-gutters><v-col class="pr-2"><v-card class="pl-2" color="green" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRAR {{ item.fechaparacerrar }} </span></v-card></v-col></v-row>
        </v-col>
        <v-col cols="12" md="3" align-self="center">
            <v-row no-gutters align="center">
              <v-col cols="12" md="10">
                  <v-row no-gutters class="pt-1" v-for="(itemesc, index) in item.escalamientos" :key="index">
                    <v-col>
                    <v-chip small outlined color="green">
                      <v-avatar left><v-icon size="15">mdi-check-bold</v-icon></v-avatar>
                      <span style="font-size:10px">{{ itemesc.escaladoa.nombre_usuario + ' ' + itemesc.escaladoa.apellido_usuario }}</span>
                    </v-chip>
                    </v-col>
                  </v-row>
              </v-col>
              <v-col cols="12" md="2" align-self="center" class="text-center">
                <v-btn @click="ver(item)" icon color="blue"><v-icon>mdi-pencil</v-icon></v-btn>
              </v-col>
            </v-row>
        </v-col>
      </v-row>
    </v-list-item>
    <v-divider></v-divider>
  <lecturaDialog :dialogLectura="dialogLectura" :session="session" :items="items" :rules="rules" :colores="colores" :token="token" :registro_id="registro_id" @closed_ventana="cerrar_ventana"></lecturaDialog>
</div>
</template>

<script>
import lecturaDialog from '@/components/inbound/lectura_dialogoComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandeja_pqrsComponent',
  components: {
    lecturaDialog
  },
  mixins: [recFunciones],
  props: ['session', 'item', 'colores', 'rules', 'items'],
  data: () => ({
    dialogLectura: false,
    token: 0,
    registro_id: 0
  }),
  methods: {
    ver (pItem) {
      this.dialogLectura = true
      this.token = this.token + 1
      this.registro_id = pItem.id
    },
    cerrar_ventana () {
      this.dialogLectura = false
    }
  }
}
</script>
